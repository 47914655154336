import { Modal } from '@simplywallst/ui-core';
import { pluralise } from '@/utilities/pluralise';

import type { getTickerCellTemplate } from '../../../CustomCell/Ticker';

type Props = {
  oldValue: ReturnType<typeof getTickerCellTemplate>;
  newValue: ReturnType<typeof getTickerCellTemplate>;
  matchRowCount: number;
  onConfirm: () => void;
  onCancel: () => void;
};

export const TickerRematchModal = ({
  matchRowCount,
  oldValue,
  newValue,
  onConfirm,
  onCancel,
}: Props) => {
  if (newValue.data.uniqueSymbol === null) return null;
  if (oldValue.data.uniqueSymbol === null && oldValue.data.value === null)
    return null;

  return (
    <Modal
      title="Update all matching tickers?"
      onPrimaryClick={onConfirm}
      onSecondaryClick={onCancel}
      secondaryLabel="No"
      primaryLabel={pluralise(matchRowCount, 'Update', 'Update all')}
    >
      <div className="grid gap-8">
        <div className="grid grid-rows-[repeat(4,auto)] gap-2">
          <p className="mb-0 text-sm text-legacy-darkgray">
            You just updated...
          </p>
          <p className="mb-0 text-sm text-legacy-mediumgray">
            <span className="block text-pretty line-through">
              {oldValue.data.name}
            </span>
            <span className="block line-through">
              {oldValue.data.uniqueSymbol ?? oldValue.data.value}
            </span>
          </p>
          <span className="text-legacy-darkgray">&darr;</span>
          <p className="mb-0 text-sm">
            <span className="block text-pretty text-legacy-darkergray">
              {newValue.data.name}
            </span>
            <span className="block text-legacy-darkgray">
              {newValue.data.uniqueSymbol}
            </span>
          </p>
        </div>
        <p className="mb-0 text-sm text-legacy-darkgray">
          You have {matchRowCount} {pluralise(matchRowCount, 'other', 'more')}{' '}
          matching {pluralise(matchRowCount, 'ticker', 'tickers')}. Do you want
          to {pluralise(matchRowCount, 'Update that too ?', 'Update them too?')}
        </p>
      </div>
    </Modal>
  );
};
